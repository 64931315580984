'use client';
import { locales } from '@/config/http/base';
import { dealLng } from '@/utils';
import { Button } from '@nextui-org/button';
import { useRouter } from 'next/navigation';


export default function NotFound(props: {
  lng: string;
  redirectPath?: string;
  className?: string;
}) {
  const router = useRouter();
  const goHome = () => {
    if (props.redirectPath) {
      router.replace(`${dealLng(props.lng)}/${props.redirectPath}`);
    } else {
      router.replace('/');
    }
  };

  let lng = props.lng as 'en' | 'fr' | 'es' | 'de';

  if (!locales.includes(lng)) {
    lng = 'en';
  }
  const lngData = {
    en: {
      title: '404',
      description: 'Sorry, the page you visited does not exist.',
      back: 'Back Home',
    },
    fr: {
      title: '404',
      description: 'Désolé, la page que vous avez visitée n\'existe pas.',
      back: 'Retourner à l\'accueil',
    },
    es: {
      title: '404',
      description: 'Lo sentimos, la página que visitaste no existe.',
      back: 'Volver al inicio',
    },
    de: {
      title: '404',
      description: 'Lo sentimos, la página que visitaste no existe.',
      back: 'Volver al inicio',
    },
  };

  return (
    <div className={ `h-screen w-full ${ props.className }` }>
      <h2 className='font-bold text-28-43 text-center pt-10'>404</h2>
      <p className='text-center pt-5'>{ lngData[lng]?.description }</p>
      <div className='flex justify-center pt-10'>
        <Button color='primary' onClick={ goHome }>{ lngData[lng]?.back }</Button>
      </div>
    </div>
  );
}
